/* 热门产品 */
.hot-products {
    height: 365px;
    background: #fff;
    padding-left: 30px;
}
.tab-title {
    padding: 30px 0;
}
.tab-title li {
    display: inline-block;
    zoom: 1;
    *display: inline;
    font-size: 20px;
    cursor:pointer;
    color: #777;
    margin-right: 60px;
    border-bottom: 5px solid #fff;
    height: 25px;
    line-height: 25px;
    position: relative;
    font-family: "Microsoft YaHei";
    transition: color 0.2s ease-in;
    padding-bottom: 2px;
}
.tab-title .on:hover {
   color: #050505; 
}
.tab-title li:hover {
    color: #3e99dc;
}
.tab-title li .hot {
    display: block;
    width: 45px;
    height: 38px;
    position: absolute;
    background: url("/imgs/icon/hot.png") left top no-repeat;
    right: -27px;
    top: -36px;
}
.tab-title .on {
    border-bottom: 5px solid #3e99dc;
    color: #050505;
}
.hot-products .pro-list li {
    float: left;
    display: inline;
    width: 448px;
    padding-bottom: 12px;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 20px;
}
.hot-products .pro-list a {
    transition: color 0.2s ease-in;
}
.hot-products .pro-list a:hover {
    color: #3e99dc;
    text-decoration: none;
}
.hot-products .sale {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    color: #fff;
    background: #ccc;
    margin-right: 10px;
}
.hot-products .pro-list .img-item {
    width: 210px;
    padding-bottom:15px;
    margin-right: 23px;
}
.hot-products .pro-list .img-item-box {
    position: relative;
    width: 210px;
    height: 160px;
    overflow: hidden;
}
.hot-products .pro-list .img-wrap {
    height: 160px;
    display: block;
    // position: relative;
    // overflow: hidden;
}
.hot-products .img-wrap img {
    // position: absolute;
    // left: 50%;
    // margin-left: -105px;
}
.hot-products .img-item .sale {
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    height: 32px;
    font-size: 16px;
    font-family: Tahoma;
    margin-right: 0;
    text-indent: 10px;
    // z-index: 2;
}
.img-item .sale1 {
    background: url("/imgs/icon/sale_bg.png") left top no-repeat; 
}
.img-item .sale2 {
    background: url("/imgs/icon/sale_bg.png") left -32px no-repeat; 
}
.img-item .sale3 {
    background: url("/imgs/icon/sale_bg.png") left -64px no-repeat; 
}
.img-item .sale4 {
    background: url("/imgs/icon/sale_bg.png") left -96px no-repeat; 
}
.hot-products .img-item .name {
    display: block;
    position: absolute;
    height: 42px;
    line-height: 18px;
    padding-top: 8px;
    left: 0;
    bottom: 0px;
    width: 210px;
    background: url("/imgs/icon/bg_black.png");
    background: rgba(0,0,0,.6);
    color: #fff;
    font-size: 14px;
    white-space: normal;
}
.hot-products .img-item .name:hover {
    color: #fff;
    text-decoration: underline;
}
.hot-products .img-item .full-name {
    height: 36px;
    overflow: hidden;
    padding: 0 5px;
    display: block;
    cursor: pointer;
}
.hot-products .price-box {
    color: #888;
    font-size: 14px;
    margin-top: 5px;
    position: relative;
    min-height: 35px;
    height: 60px;
    _height: 60px;
    text-align: right;
}
.hot-products .old-price {
    text-decoration: line-through;
    display: block;
    text-align: right;
}
.hot-products .new-price {
    font-family: "Microsoft YaHei";
    background: #ff6c00;
    border-radius: 1px;
    position: absolute;
    padding-left: 5px;
    color: #fff;
    right: 0;
    top: 40px;
    vertical-align:top;
    overflow:hidden;
    line-height: 26px;
    _line-height:26px;
    *zoom:1;
}
.hot-products .new-price span {
    display: inline-block;
    text-align: center;
    line-height: 24px;
    overflow: hidden;
    margin-left: 4px;
    background: #fff;
    font-size: 20px;
    min-width: 80px;
    padding: 0 5px;
    color: #ff6c00;
    height: 24px;
    border: 1px solid #ff6c00;
    vertical-align: top;
    *vertical-align:middle;
}
.hot-products .new-price em {
    font-size: 14px;
}
.hot-products .more {
    text-align: right;
    padding-right: 30px;
}
.hot-products .more a {
    color: #00388a;
    transition: color 0.2s ease-in;
}
.hot-products .more a:hover {
    color: #3e99dc;
    text-decoration: none;
}
.hot-products .des {
    color: #7fc5f9;
    padding: 0 30px 0 45px;
    background: url("/imgs/icon/des_bg.png") left top no-repeat;
    margin-bottom: 15px;
}
.hot-products .des a {
    color: #7fc5f9;
}
.hot-products .des p {
    padding-right: 45px;
    background: url("/imgs/icon/des_bg1.png") right bottom no-repeat; 
}
.hot-products .des span {
    display: block;
    font-size: 20px;
    font-family: "Microsoft YaHei";
    margin-bottom: 5px;
}