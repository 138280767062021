/*
	by stephan
	首页改版
	内容左侧页面样式
*/
body {
	min-width: 1200px;
}

.w-1200 {
	width: 1200px;
	margin: auto;
}

.main-menu {
	z-index: 3;
}

/* 左侧base */
.main {
	margin: 0 auto;
	width: 1200px;
	padding-top: 20px;
}

.aside {
	width: 220px;
	background: #fff;
	float: left;
}

.column {
	position: relative;
}

.column .more {
	position: absolute;
	right: 10px;
	top: 30px;
	height: 28px;
	line-height: 28px;
	color: #00388a;
	transition: color 0.2s ease-in;
}

.column .more:hover {
	text-decoration: none;
	color: #3e99dc;
}

.column h3 {
	position: relative;
	left: -2px;
	padding-top: 30px;
	font-weight: normal;
}

.column .time {
	color: #b5b5b5;
	font-family: Tahoma;
}

.column .tit {
	height: 28px;
	line-height: 28px;
	display: inline-block;
	padding: 0 12px;
	color: #fff;
	background: url("/imgs/icon/aside_tit.png") left top no-repeat;
	font-size: 14px;
}

.column .bubble {
	font-size: 12px;
	color: #7fc5f9;
	margin-left: 10px;
}

/* 途风公告 */
.notice {
	height: 200px;
}

.notice .more {
	top: 20px;
}

.notice li {
	padding-left: 22px;
	padding-top: 10px;
	background: url("/imgs/icon/notice_bg.png") 12px 18px no-repeat;
}

.notice li a {
	display: block;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	transition: all 0.2s ease-in;
	width: 198px;
}

.notice li a:hover {
	color: #3e99dc;
	text-decoration: none;
}

/* 学生专区 */
.column-ad {
	a {
		display: block;
		margin-top: 10px;
		margin-bottom: 5px;
	}

	img {
		max-width: 100%;
		width: 220px;
		height: 129px;
	}

}

/* 旅游主题 */
.theme {
	// height: 433px;
	border-top: 10px solid #f2f2f2;

	h3 {
		padding-top: 0;
	}

}

.theme ul {
	padding-top: 10px;
	border-bottom: 1px solid #f2f2f2;
}

.theme li {
	width: 109px;
	height: 90px;
	border: 1px solid #f2f2f2;
	border-left: 0;
	border-bottom: 0;
	float: left;
}

.theme .old {
	border-right: 0;
	width: 110px;
}

.theme li a {
	display: block;
	padding-top: 55px;
	height: 35px;
	text-align: center;
}

.theme .fir a {
	background: url("/imgs/icon/theme_icon.png") 0 0 no-repeat;
}

.theme .sec a {
	background: url("/imgs/icon/theme_icon.png") -115px 0 no-repeat;
}

.theme .thi a {
	background: url("/imgs/icon/theme_icon.png") 0 -90px no-repeat;
}

.theme .for a {
	background: url("/imgs/icon/theme_icon.png") -115px -90px no-repeat;
}

.theme .fif a {
	background: url("/imgs/icon/theme_icon.png") 0 -180px no-repeat;
}

.theme .six a {
	background: url("/imgs/icon/theme_icon.png") -115px -180px no-repeat;
}

.theme .sev a {
	background: url("/imgs/icon/theme_icon.png") -5px -273px no-repeat;
}

.theme .eig a {
	background: url("/imgs/icon/theme_icon.png") -115px -273px no-repeat;
}

.aside .theme li a:hover {
	text-decoration: none;
	background-color: #f2f2f2;
}

/* 结伴同游 */
.together {
	height: 200px;
}

.together  li {
	padding-left: 22px;
	padding-top: 10px;
	background: url("/imgs/icon/notice_bg.png") 12px 16px no-repeat;
}

.together  li a {
	display: block;
	height: 15px;
	line-height: 15px;
	margin-bottom: 3px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	padding-right: 5px;
	transition: all 0.2s ease-in;
	width: 193px;
}

.together  li a:hover {
	text-decoration: none;
	color: #3e99dc;
}

/* 照片分享 */
.photo {
	height: 238px;
}

.photo ul {
	padding: 20px 0 0 10px;
}

.photo li {
	width: 95px;
	height: 70px;
	float: left;
	overflow: hidden;
	padding-right: 10px;
	padding-bottom: 10px;
}

.photo li a {
	display: block;
	width: 95px;
	height: 70px;
	transition: all 0.2s ease-in;
}

.photo li a:hover {
	opacity: 0.8;
}

.photo .old {
	padding-right: 0;
}

/* 问题咨询 */
.question {
	height: 245px;
}

.question  li {
	padding-left: 30px;
	padding-top: 10px;
	background: url("/imgs/icon/ask_bg.png") 12px 13px no-repeat;
}

.question  li a {
	display: block;
	height: 15px;
	line-height: 15px;
	margin-bottom: 3px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	padding-right: 5px;
	transition: all 0.2s ease-in;
	width: 185px;
}

.question  li a:hover {
	text-decoration: none;
	color: #3e99dc;
}

/* 游客评论 */
.comment {
	height: 369px;
}

.percent-column a {
	background: url("/imgs/icon/star_h.png") no-repeat 0 0;
	height: 15px;
	position: relative;
	width: 99px;
	display: block;
	float: left;
}

.percent-column .percent-text {
	float: left;
	color: #777;
	padding-left: 10px;
}

.percent-column a span {
	background: url("/imgs/icon/star.png") no-repeat 0 0;
	display: block;
	height: 15px;
	left: 0;
	position: absolute;
	top: 0;
}

.comment ul {
	padding-top: 10px;
}

.comment li {
	position: relative;
	padding-bottom: 15px;
	min-height: 40px;
	_height: 40px;
}

.comment .text {
	padding-left: 12px;
}

.comment .text a {
	display: block;
	width: 208px;
	color: #777;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.comment .text a:hover {
	text-decoration: none;
	color: #3e99de;
}

.comment .percent-column {
	height: 15px;
}

.comment img {
	position: absolute;
	left: 12px;
	top: 0;
}

.comment .info {
	height: 45px;
	padding-left: 60px;
}

.comment .name {
	margin-bottom: 3px;
	color: #777;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

/*
	左侧新增banner
*/
.aside-banner {
	height: 280px;
	border-top: 10px solid #f2f2f2;
}

/*
	首页右侧页面样式
*/
.toside {
	float: right;
	width: 970px;
}

@import url("module/hot_products.module.less");

/* 广告位 */
.ad-list-wrap {
	margin-top: 10px;
	height: 80px;
	overflow: hidden;
	position: relative;
}

.ad-list a {
	display: block;
	height: 80px;
	position: relative;
}

.ad-list a img {
	position: absolute;
	left: 50%;
	margin-left: -485px;
}

@import url("module/theme.module.less");

/*
	底部banner和我们的优势
*/
.bottom-banner-warp {
	width: 1200px;
	margin: 0 auto;
	height: 100px;
	margin-top: 25px;
	overflow: hidden;
}

.bottom-banner {
	margin-right: -15px;
}

.bottom-banner a {
	display: block;
	width: 390px;
	height: 100px;
	position: relative;
	float: left;
	margin-right: 15px;
	overflow: hidden;
}

.bottom-banner a img {
	position: absolute;
	left: 50%;
	margin-left: -195px;
}

.bottom-banner .last {
	margin-right: 0;
}

.our-advance {
	width: 1200px;
	margin: 0 auto;
	margin-top: 35px;
	margin-bottom: 60px;
}

.our-advance h3 {
	font-size: 16px;
	color: #050505;
	font-weight: normal;
}

.our-advance ul {
	height: 130px;
	background: #7fc5f9;
	margin-top: 10px;
	padding-left: 5px;
}

.our-advance li {
	width: 170px;
	float: left;
	height: 130px;
}

.our-advance a {
	display: block;
	padding-top: 85px;
	height: 45px;
	color: #fff;
	text-align: center;
	font-size: 14px;
}

.our-advance .advance-01 {
	background: url("/imgs/icon/advance_01.png") center 38px no-repeat;
}

.our-advance .advance-02 {
	background: url("/imgs/icon/advance_02.png") center 37px no-repeat;
}

.our-advance .advance-03 {
	background: url("/imgs/icon/advance_03.png") center 31px no-repeat;
}

.our-advance .advance-04 {
	background: url("/imgs/icon/advance_04.png") center 35px no-repeat;
}

.our-advance .advance-05 {
	background: url("/imgs/icon/advance_05.png") center 30px no-repeat;
}

.our-advance .advance-06 {
	background: url("/imgs/icon/advance_06.png") center 34px no-repeat;
}

.our-advance .advance-07 {
	background: url("/imgs/icon/advance_07.png") center 31px no-repeat;
}

/*重置菜单样式 update by Leon 2015年5月29日 09:03:35 START*/
.classify .home,
.classify-hover .home,
.classify:hover .home {
	background-image: none;
}

.main-menu {
	display: block;
}

/*首页banner*/
@import url('./home/banner.module.less');

.index-top-banner {
	position: relative;
}

.top-small-banner {
	position: absolute;
	width: 130px;
	left: 50%;
	margin-left: 470px;
	top: 0;
	height: 390px;
}