/*
 * 首页banner
 *
 * @author Leon
 *
 * 2016年4月28日 23:27:01
 */
.index-banner {
    height: 390px;
    overflow: hidden;
    margin-left: 220px;
    margin-right: 130px;
    position: relative;

    .img-list {
        position: relative;
    }

    .img-item {
        float: left;
        // display: block;
    }

    .img-item-cur {
    }

    .img-link {
    }

    .nav-list {
        margin-right: -10px;
        position: absolute;
        bottom: 0;
    }

    .nav-item {
        border-right: 1px solid #9B9B9B;
        float: left;
        color: #fff;
    }

    .nav-item-last {
        border: none;
    }

    .nav-link {
        height: 36px;
        line-height: 36px;
        color: #fff;
        display: block;
        text-decoration: none;
        font-size: 12px;
        font-family: Arial, "\5B8B\4F53", Verdana;
        text-align: center;
        background: rgba(0, 0, 0, 0.7);
        filter: progid:DXImageTransform.Microsoft.Gradient(StartColorStr='#b3000000', EndColorStr='#b3000000');
        color: #fff;
        transition: background  ease .1s;
    }

    .nav-link:hover,
    .nav-item-cur .nav-link {
        background: rgba(0, 117, 203, 0.7);
        filter: progid:DXImageTransform.Microsoft.Gradient(StartColorStr='#b30075cb', EndColorStr='#b30075cb');
    }

    .nav-ctrl {
        width: 100%;
        margin-top: -31px;
        position: absolute;
        top: 50%;
        display: none;
        height: 0;

        .ctrl-item {
            width: 28px;
            height: 62px;
            text-align: center;
            line-height: 62px;
            background: rgba(0, 0, 0, .4);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#32000000', endColorstr='#32000000');
            color: #fff;
            font-family: simsun;
            font-size: 22px;
            text-decoration: none;
        }

        .ctrl-prev {
            float: left;
        }

        .ctrl-next {
            float: right;
        }

    }

    &:hover {
        .nav-ctrl {
            display: block;
        }

    }

}