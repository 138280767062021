/* 三大主题（美东、美西、欧洲）推荐产品 */
.pro-column {
    height: 370px;
    background: #fff;
    margin-top: 10px;
    padding-left: 30px;
    position: relative;
    overflow: hidden;
}
.pro-column .more {
    position: absolute;
    top: 30px;
    right: 30px;
    height: 20px;
    line-height: 20px;
    color: #00388a;
    transition: color 0.2s ease-in;
}
.pro-column .more:hover {
    color: #3e99dc;
    text-decoration: none;
}
.place-tab {
    padding: 30px 0 20px 0;
}
.place-tab li {
    width: 160px;
    height: 38px;
    float: left;
    color: #333;
    line-height: 15px;
    position: relative;
    cursor: pointer;
    border: 1px solid #fff;
    margin-right: 20px;
    transition: color 0.2s ease-in;
}
.pro-column .place-tab .spec:hover,
.place-tab li:hover {
    color: #3e99dc;
}
.place-tab li:hover .ename {
    color: #777;
}
.place-tab .on {
    border-color: #ff5050;
    background: #fef1f1;
}
.east .place-tab .on {
    border-color: #5fc6f1;
    background: #f2fbff;
}
.europe .place-tab .on {
    border-color: #f8ca67;
    background: #fdf8ec;
}
.australia .place-tab .on {
    border-color: #4ddfda;
    background: #f3fdfd;
}
.place-tab .icon {
    display: block;
    width: 40px;
    height: 40px;
    background: url("/imgs/icon/place_icon.png") no-repeat;
    position: absolute;
    left: -1px;
    top: -1px;
}
.place-tab .icon-style1 {
    background-position: left top;
}
.place-tab .icon-style2 {
    background-position: left -40px;
}
.place-tab .icon-style3 {
    background-position: left -80px;
}
.place-tab .icon-style4 {
    background-position: left -40px;
}
.place-tab .icon-style5 {
    background-position: left -120px;
}
.place-tab .on .icon-style1 {
    background-position: left -160px;
}
.place-tab .on .icon-style2 {
    background-position: left -200px;
}
.place-tab .on .icon-style3 {
    background-position: left -240px;
}
.place-tab .on .icon-style4 {
    background-position: left -280px;
}
.place-tab .on .icon-style5 {
    background-position: left -320px;
}
.place-tab li p {
    padding-left: 45px;
    padding-top: 5px;
}
.pro-column .place-tab .spec {
    width: 880px;
    font-size: 20px;
    line-height: 20px;
    height: 20px;
    border: none;
    border-left: 5px solid #7fc5f9;
    padding-left: 10px;
    color: #050505;
    font-family: 'microsoft yahei';
    background: none;
    margin-bottom: 20px;
}
.place-tab .ename {
    display: block;
    font-family: Tahoma;
    color: #777;
}
.place-pro-list li {
    float: left;
    position: relative;
    width: 170px;
    height: 210px;
    border: 1px solid #eee;
    position: relative;
    margin-left: -1px;
}
.place-pro-list li:hover {
    background: #eee;
}
.place-pro-list .img-wrap {
    display: block;
    padding: 15px 15px 0;
}
.place-pro-list .name {
    display: block;
    padding: 10px 15px 0;
    font-size: 14px;
    color: #777;
    line-height: 18px;
    height: 54px;
    overflow: hidden;
    transition: color 0.2s ease-in;
}
.place-pro-list li .name:hover {
    color: #3e99dc;
    text-decoration: none;
}
.place-pro-list .price {
    position: absolute;
    right: 15px;
    bottom: 10px;
    color: #ff6c00;
    font-size: 14px;
    font-family: Arial,Verdana,"\5B8B\4F53";
    font-weight: bold;
}
.place-pro-list .big-item {
    width: 400px;
    border-left: 1px solid #eee;
    margin: 0;
    overflow: hidden;
}
.place-pro-list .big-item .price {
    font-size: 16px;
    right: 10px;
    bottom: 15px;
}
.place-pro-list .price em{
    font-weight: bold;
    font-size: 10px;
}
.place-pro-list .big-item em{
    font-weight: bold;
    font-size: 12px;
}
.place-pro-list .big-item .name {
    width: 400px;
    background: rgba(0, 0, 0, .6);
    display: block;
    position: absolute;
    height: 42px;
    line-height: 18px;
    padding: 0;
    padding-top: 8px;
    left: 0;
    bottom: 0px;
    background: url("/imgs/icon/bg_black.png");
    background: rgba(0,0,0,.6);
    color: #fff;
    font-size: 14px;
}
.place-pro-list .big-item .name:hover {
    text-decoration: underline;
    color: #fff;
}
.place-pro-list .big-item .name span {
    width: 298px;
    padding-left: 5px;
    overflow: hidden;
    height: 36px;
    padding: 0 5px;
    display: block;
    cursor: pointer;
}
